import * as React from "react";
import Button from "../components/button";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Cta from "../components/cta";

const TransportationPage = () => (
  <Layout backgroundColor="#FBFBEF">
    <Seo title="Transportation" />
    <h1>Transportation</h1>

    <h2>Rideshare</h2>
    <p>
      The ceremony and reception is about a 0.5 mile walk from the nearest bus
      stop. We recommend taking a ride share to make things a bit easier.
    </p>

    <p>
      <Button text="Uber" url="https://uber.com" />
      <Button text="Lyft" url="https://lyft.com" />
    </p>

    <h2>Public Transporation</h2>
    <p>
      There are two major public transportation options in the Bay Area,{" "}
      <a href="https://www.sfmta.com" rel="noreferrer" target="_blank">
        Muni
      </a>{" "}
      and{" "}
      <a href="https://www.bart.gov" rel="noreferrer" target="_blank">
        BART.
      </a>{" "}
      Muni is a network of buses and trains that can get you around in San
      Francisco and BART is a network of subways that stop less frequently and
      travel to Oakland and Berkeley in the East Bay.
    </p>

    <p>
      It’s worth noting the venue for the ceremony is not near a train station
      and is about a half mile from the nearest bus stop. The restaurant for the
      welcome dinner as well as the park are very close to stops on the{" "}
      <strong>N Judah train route</strong>.
    </p>

    <p>
      If you’re interested in using public transportation the easiest method
      will be to use a{" "}
      <a href="https://transit.applepay.apple/san-francisco">
        digital Clipper Card
      </a>{" "}
      which is compatible with both systems. BART will have card readers before
      entering the train platform and MUNI will have card readers within the
      buses and trains.
    </p>

    <p>
      <Button
        text="Apple Pay"
        url="https://transit.applepay.apple/san-francisco"
      />
      <Button
        text="Google Pay"
        url="https://www.clippercard.com/ClipperWeb/google-pay"
      />
    </p>

    <Cta url="/lodging" text="Decide where to stay" />
  </Layout>
);

export default TransportationPage;
