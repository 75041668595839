import * as React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const CTAContainer = styled.div`
    margin-top:120px;
    margin-bottom:60px;
    // background:yellow;
    display:flex;
    align-items:bottom;
    flex-direction:row;
    justify-content:bottom;
    height:3em;
    font-weight:bold;
`

const StyledLink = styled(props => <Link {...props} />)`
  color:black;
  text-transform:uppercase;
  display:inline-block;
  margin-right:1em;
  padding:0.125em 0 0.125em 0;
  text-decoration:none;
  border-bottom:1px solid black;
  margin-top: auto;
  transition: padding 0.3s ease-in-out;
  
  .arrow{
      transition: all 0.2s ease-in-out;
  }

  &:active{
        opacity: 0.5;
  }

  &:hover{
      padding: 0.125em 0 0.5em 0;
      margin-bottom:0;

      .arrow{
          margin-left: 1.25em;
      }
  }
`;



export default function Cta({url,text}){
    return(
        <CTAContainer>   

                <StyledLink to="/rsvp">
                    RSVP
                </StyledLink>


                <StyledLink to={url}>
                    {text} <span className="arrow">&#8594;</span>
                </StyledLink>

        </CTAContainer>
    )

}