import * as React from "react"
import styled from "styled-components"

const StyledButton = styled.button`
    background:none;
    border:unset;
    display:inline-block;
    margin-right:32px;
    padding-left:0;
    padding-right:0;
    border-bottom:1px solid black;
    transition: transform 0.2s ease-in-out;

   

   a{
       text-decoration:none;
       border-bottom:none;
       color:black;
   }
`

export default function Button({text,url}){
    return(
        <StyledButton>
            <a href={url} target="_blank" rel="noreferrer">
                {text}
            </a>
        </StyledButton>
    )

}